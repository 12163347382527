<template>
  <div class="main-layout">
    <div class="px-5 content-set">
      <div class="card--payment">
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <img :src="require('../assets/images/ilustrations/IluPendingPaid.png')" height="170" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <strong class="subheading">{{ $t("pending_payment") }} ⚠️</strong>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="d-flex align-center justify-center">
            <p class="content text-center">
              {{ $t("the_order_has_been_pending") }}
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="align-center justify-center">
            <p class="text-center title-invoice">
              {{ $t("page.referral.order_num") }} :
            </p>
            <strong > 
              <p class="subheading text-center iconsSet" >{{ order_number_desc }} </p>
            </strong>
            <p class="text-center title-invoice">
              {{ $t("page.referral.order_desc") }} :
            </p> 
            <strong > 
              <p class="subheading-desc text-center" >{{ product_desc }} </p>
            </strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <localized-link to="/patient/profile?tab=PAYMENTINFO">
              <c-button rounded shadow bold>
                {{ $t("view_order") }}
              </c-button>
            </localized-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <localized-link to="/">
              <icons icon="house" color="#00a4b3" />
            </localized-link>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from "@/components/elements/Icons.vue";
import CButton from "@/components/v2/button/index.vue";
import API from "../service/api_service";
import Auth from "../service/auth_service";
export default {
  components: {
    Icons,
    CButton,
  },
  data: () => ({
    tableInvoice: [],
    groupItemCategory: [],
    dataDialogInvoice: {
      order_number: null,
      list: [],
      data: {
        metadata: {},
      },
    },
    dialog_invoice: false,
    order_number_desc: '',
    product_desc:'',
    test:[],
    home_care: 'false',
  }),
  created(){
    this.home_care = this.$route.query.home_care;
    this.resetCart()
    if(this.$route.query.order_id){

      API.get(
        `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${this.$route.query.order_id}`,
        {
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      ).then(res => {
          this.order_number_desc = res.order_number;
          API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` + this.order_number_desc + ``,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          ).then(rest => {
            this.product_desc = rest.results.package_name;
          });
      }); 
    }    
  },
  methods: {
    async resetCart() {
      // reset cart

      try {
        await API.post(
          `${process.env.VUE_APP_API_TRUST}cart/empty`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          {}
        );
      } catch (error) {
        console.log(error);
      }
    },
    async openDialogInvoice(dat){
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}invoices/get-detail?order_number=` +
            dat +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogInvoice.data = res.results;
        this.dataDialogInvoice.list = res.results.data;
        const resMyJSON = this.dataDialogInvoice.list.map(element => {
          return element.product_name
        });
        const myJSON = JSON.stringify(resMyJSON);
        this.product_desc = myJSON.replace(/[^ a-z A-Z 0-9 , ()/]/g,'');
      } catch (error) {
        console.log(error);
      }   
    }
  },
  
};
</script>

<style lang="scss" scoped>
@import "../assets/css/custom.scss";

.card--payment {
  padding: 32px 15px;
  border-radius: 16px;
}

.header {
  padding: 30px 0 10px 0;
}
.subheading {
  color: rgba(121, 196, 198, 1);
  font-size: 18px;
}
.rp {
  color: $gray_2;
  font-size: 18px;
  margin-top: 40px;
}
.to-home {
  color: $gray_2;
  font-size: 18px;
}
.content {
  font-size: 16px;
  margin-top: 15px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-amount {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
}
.content-set {
  padding: 10px 0 0px 0;
}
.amount {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  padding: 15px 40px;
  border-radius: 15px;
}
a {
  text-decoration: none;
}
.iconsSet {
  margin: 0 30%;
}
.title-invoice{
  font-size: 16px;
  font-weight: 500;
  color: rgba(123, 140, 174, 1);
  margin-bottom: 0;
}
.subheading-desc {
  color: rgba(121, 196, 198, 1);
  font-size: 14px;
  margin-bottom: 0;
}

@media only screen and (max-width: 714px) {
  .iconsSet {
    margin: 0 20%;
  } 
}
</style>
